import "moment/locale/fr";

import clsx from "clsx";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { useSnackbar } from "notistack";
import React from "react";

import link from "../../../../resources/images/actuality-link/link.svg";
import linkedin from "../../../../resources/images/actuality-link/linkedin.svg";
import { Like } from "./components/Like";
import * as styles from "./InspirationFooterFocus.module.css";

export const InspirationFooterFocus = ({ author, url }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <div className={styles.container}>
      <div className={styles.author}>
        <div className={styles.authorPicture}>
          {author.picture ? (
            <GatsbyImage image={author.picture.gatsbyImageData} alt="picture" />
          ) : (
            <StaticImage
              src="../../../../resources/images/actuality-content/unknow.png"
              alt="unknow"
            />
          )}
        </div>
        <div className={styles.authorInfo}>
          <div className={styles.authorName}>{author.name}</div>
          <div className={styles.authorJob}>{author.job}</div>
        </div>
      </div>
      <div className={styles.like}>
        <Like url={url} />
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`,
            "_blank"
          );
        }}
        className={styles.link}
      >
        <img src={linkedin} alt="logo" />
      </div>
      <div
        className={clsx(styles.link, styles.copyLink)}
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          enqueueSnackbar("Lien copié", {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }}
      >
        <img src={link} alt="logo" />
      </div>
    </div>
  );
};
