import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { InspirationPodcastExternalPageContext } from "../../helpers/pagesContext";
import { INSPIRATIONS } from "../../helpers/route";
import PodcastExternalScene from "../../scenes/PodcastExternal/PodcastExternal";

export default function Inspiration(props) {
  const inspirationPodcastExternal = props.data.inspirationPodcastExternal;
  const inspirationContainer = props.data.inspirationContainer;
  const resultLastInspirationPodcastExternal =
    props.data.lastInspirationPodcastExternal.edges.filter(
      (element) => element.node.title !== "dummy",
    );

  return (
    <Layout currentRoute={INSPIRATIONS} data={props.data.contentfulMenuLabel}>
      <Seo
        title={inspirationPodcastExternal.title}
        image={
          inspirationPodcastExternal.thumbnail.gatsbyImageData.images.fallback
            .src
        }
        description={inspirationPodcastExternal.metaDescription}
      />
      <InspirationPodcastExternalPageContext.Provider
        value={{
          inspirationPodcastExternal,
          resultLastInspirationPodcastExternal,
          inspirationContainer,
        }}
      >
        <PodcastExternalScene />
      </InspirationPodcastExternalPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($idUrl: String!, $locale: String!) {
    inspirationPodcastExternal: contentfulInspirationPodcastExternal(
      idUrl: { eq: $idUrl }
      node_locale: { eq: $locale }
    ) {
      node_locale
      idUrl
      metaDescription
      id
      title
      thumbnail {
        gatsbyImageData(placeholder: BLURRED)
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      author
      authorJob
      authorPicture {
        gatsbyImageData
      }
      time
      source
      url
      creation(formatString: "DD MMM YYYY", locale: "fr")
    }
    lastInspirationPodcastExternal: allContentfulInspirationPodcastExternal(
      limit: 3
      sort: { fields: creation, order: DESC }
      filter: { idUrl: { ne: $idUrl }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          idUrl
          id
          title
          thumbnail {
            gatsbyImageData(layout: FIXED)
          }
          content {
            raw
          }
          author
          time
          source
          url
          createdAt(formatString: "YYYY-MM-DD HH:mm:ss")
        }
      }
    }
    inspirationContainer: contentfulInspirationContainer(
      node_locale: { eq: $locale }
    ) {
      otherPodcastExternal
      keepExploring
      goingBack
      node_locale
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
