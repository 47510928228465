import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";

import * as stylesInspiration from "./FormattedTextInspiration.module.css";
import * as stylesOpportunity from "./FormattedTextOpportunity.module.css";

export const FormattedText = ({ content, inspiration = true }) => {
  const options = {
    renderText: (text) =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <div
      className={inspiration ? stylesInspiration.text : stylesOpportunity.text}
    >
      {renderRichText(content, options)}
    </div>
  );
};
