import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { FormattedText } from "../../../../components/FormattedText/FormattedText";
import { InspirationFooterFocus } from "../../../../components/Inspiration/components/InspirationFooter/InspirationFooterFocus";
import { InspirationPodcastExternal } from "../../../../components/Inspiration/Inspiration";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { InspirationPodcastExternalPageContext } from "../../../../helpers/pagesContext";
import { INSPIRATIONS } from "../../../../helpers/route";
import * as styles from "./Body.module.css";

export const Body = () => {
  const {
    inspirationPodcastExternal: {
      id,
      idUrl,
      content,
      url,
      author,
      authorPicture,
      authorJob,
    },
    resultLastInspirationPodcastExternal,
    inspirationContainer: { goingBack, keepExploring, otherPodcastExternal },
  } = useContext(InspirationPodcastExternalPageContext);

  return (
    <section className={styles.watermark}>
      <div className={styles.back}>
        <Link to={localeLink(INSPIRATIONS)}>
          <StaticImage
            src="../../../../resources/images/article/arrow.png"
            alt="arrow"
            width={33}
          />
          <span>{goingBack}</span>
        </Link>
      </div>
      <SlideTransition delay={0.4}>
        <div className={styles.root}>
          <div className={styles.container}>
            <FormattedText content={content} />
            <div className={styles.play}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <StaticImage
                  className={styles.button}
                  src="../../../../resources/images/actuality-content/button-play.png"
                  alt="button play"
                  width={80}
                />
              </a>
            </div>
            <InspirationFooterFocus
              author={{ name: author, picture: authorPicture, job: authorJob }}
              id={id}
              url={idUrl}
            />
          </div>
        </div>
      </SlideTransition>
      <div className={styles.moreMedia}>
        <p>{keepExploring}</p>
        <h3>{otherPodcastExternal}</h3>
      </div>
      <div className={styles.cardMedias}>
        {resultLastInspirationPodcastExternal.map(
          ({
            node: {
              idUrl,
              id,
              author,
              createdAt,
              title,
              source,
              content,
              thumbnail,
              time,
              url,
            },
          }) => (
            <InspirationPodcastExternal
              key={id}
              {...{
                idUrl,
                id,
                author,
                createdAt,
                title,
                content,
                thumbnail,
                source,
                url,
                time,
                delay: 0.2,
              }}
            />
          )
        )}
      </div>
    </section>
  );
};
